import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import CanvasThatDoesNotReRender from './CanvasThatDoesNotReRender';
import Chat from './Chat/Chat';
import WebGLNotSupported from './Chat/WebGLNotSupported';
import { RefContextProvider } from './Context/RefContextProvider';
import { colorOption, imageOption, skyboxOption } from 'src/store/slices/modelSlice';
import { useState } from 'react';
import ValidateModelPassword from '../Settings/ValidateModelPassword';
import { Navigate, useParams } from 'react-router-dom';

// XXX: Setting the following to "false" helps when developing UI stuff. Because the canvas is not rendered, the UI is much faster to develop.
const showCanvas = true;

const CanvasAndChat = () => {

    const { isLoading, initErrorMessage } = useSelector((state: RootState) => state.settings)
    const { modelInfo, didInit } = useSelector((state: RootState) => state.model)
    const { backgroundOptions, background } = modelInfo
    const canvasbg = !didInit || isLoading || backgroundOptions === skyboxOption
        ? {}
        : backgroundOptions === colorOption
            ? { background: background }
            : backgroundOptions === imageOption
                ? { backgroundImage: `url(${background})` }
                : {}


    if (initErrorMessage?.includes("WebGL not supported")) {
        return <WebGLNotSupported />;
    }

    return (
        <div className='canvas-wrapper'>
            {isLoading &&
                <div className='canvas-loader'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }

            <div className={`canvas-parent ${backgroundOptions === imageOption ? 'canvas-image' : ''}`}
                style={canvasbg}
            >
                {didInit && !isLoading && <Chat />}
                {showCanvas && <CanvasThatDoesNotReRender />}
            </div>
        </div>
    )
}


const Dashboard = () => {
    let { tenant } = useParams()

    const [isModelValidated, setIsModelValidated] = useState<boolean>(false)

    const handleIsModelValidated = (isModelValidated: boolean) => {
        setIsModelValidated(isModelValidated)
    }

    return (
        <>
            {
                isModelValidated ?
                    <RefContextProvider>
                        <CanvasAndChat />
                    </RefContextProvider>
                    :
                    <>
                        {
                            tenant ?
                                <ValidateModelPassword tenant={tenant} handleIsModelValidated={handleIsModelValidated} />
                                :
                                <Navigate to={"/notfound"} />
                        }
                    </>

            }
        </>
    )
}

export default Dashboard