import axios from 'axios';
import { SETTINGS, VALIDATEPASSWORD } from 'src/constants/constants';
import { composeBaseURL, handleErrors, composePath } from 'src/common/commonUtils';

// Define the base URL for your API
const BASEAPI_URL = SETTINGS;

// Create an Axios instance
const api = axios.create({
    baseURL: composeBaseURL(BASEAPI_URL),
    headers: { 'Content-Type': 'application/json' },
});

// API functions
export const apiValidatePassword = async (password: string): Promise<any> => {
    try {
        const response = await api.post(composePath(VALIDATEPASSWORD), { password });
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};