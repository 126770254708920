import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { composeThunkAction } from 'src/common/commonUtils';
import { ASSISTANT, CHAT, REJECTED, IDLE, PENDING, SENDMESSAGE, FULFILLED } from 'src/constants/constants';
import { apiSendMessage } from 'src/services/chat.service';
import { StatusError, StatusType, Voice } from 'src/types/types';

// Define types
export type ChatMessage = {
    content: string
    role: "user" | "system" | "assistant"
};

export type ChatState = {
    messages: ChatMessage[]
    isLoadingMessage: boolean
    errorMessage: string
    charCount: number
    abortController?: AbortController
    lastBotResponse: string | null
    chatStatus: StatusType;
    chatError: StatusError;
};

// Define the initial state
const initialState: ChatState = {
    messages: [],
    isLoadingMessage: false,
    errorMessage: "",
    charCount: 0, // textAreaValue
    abortController: undefined,
    lastBotResponse: null,
    chatStatus: IDLE,
    chatError: null,
};

export type SendMessageData = {
    messages: ChatMessage[]
    assistant_id: string
    voice: Voice
    vector_id: string
    documentName: string
    signal?: AbortSignal
    thread_id: string
}

// Create async thunks for authentication operations
export const sendMessage = createAsyncThunk(
    composeThunkAction(CHAT, SENDMESSAGE),
    async (sendMessageData: SendMessageData, { rejectWithValue }) => {
        try {
            const data = await apiSendMessage(sendMessageData);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// Create the slice
const chatSlice = createSlice({
    name: CHAT,
    initialState,
    reducers: {
        handleChatErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.errorMessage = payload;
        },
        handleAbortController: (state, { payload }: PayloadAction<AbortController>) => {
            state.abortController = payload;
        },
        handleIsLoadingMessage: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingMessage = payload;
        },
        handleCharCount: (state, { payload }: PayloadAction<string>) => {
            state.charCount = payload.length;
        },
        handleInitialMessage: (state, { payload }: PayloadAction<string>) => {
            state.messages = [
                {
                    content: payload,
                    role: ASSISTANT,
                }
            ];
        },
        handleNewMessage: (state, { payload }: PayloadAction<ChatMessage>) => {
            const { content, role } = payload
            const lastMessageRole = state.messages?.at(-1)?.role;
            if (lastMessageRole === ASSISTANT && role === ASSISTANT) {

                state.messages[state.messages.length - 1] = payload;

                // If a server message is done loading, set lastLLMResponse.
                if (!state.isLoadingMessage && !state.errorMessage) {
                    state.lastBotResponse = content;
                }

            } else {
                state.errorMessage = "";
                state.messages.push(payload);
            }
        },
        // Optional synchronous actions can be defined here
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.pending, (state) => {
                state.chatStatus = PENDING;
                state.chatError = null; // Clear the error when the request is pending
            })
            .addCase(sendMessage.fulfilled, (state) => {
                state.chatStatus = FULFILLED;
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.chatStatus = REJECTED;
                state.chatError = action.payload as string; // Use the custom error message
            })
    },
});

export const { handleChatErrorMessage, handleAbortController, handleCharCount, handleIsLoadingMessage, handleInitialMessage, handleNewMessage } = chatSlice.actions

export default chatSlice.reducer;
