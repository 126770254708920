import { memo, useEffect, useRef, useState } from "react";
import { initBabylon } from "src/common/plugins/Babylon/init";
import { setHumanoid } from "src/common/plugins/Babylon/setHumanoid";
import { handleInitErrorMessage, handleLoading } from "src/store/slices/settingsSlice";
import { RootState, useAppDispatch } from "src/store/store";
import { useRefContext } from "./Context/RefContextProvider";
import { getModelByTenantName, skyboxOption } from "src/store/slices/modelSlice";
import { useNavigate, useParams } from "react-router-dom";
import { composeThunkResponse } from "src/common/commonUtils";
import { FULFILLED, GETTENANT, MODEL, REJECTED } from "src/constants/constants";
import { useSelector } from "react-redux";
import { updateCamera } from "src/common/plugins/Babylon/utils";
import { Scene } from "babylonjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faStreetView } from "@fortawesome/free-solid-svg-icons";


const CanvasThatDoesNotReRender = memo(() => {
    const dispatch = useAppDispatch()
    let navigate = useNavigate()
    let { tenant } = useParams()

    const { humanoidRef } = useRefContext();
    const canvasRef = useRef<HTMLCanvasElement>(null)


    const { didInit, modelInfo } = useSelector((state: RootState) => state.model)
    const { isLoading } = useSelector((state: RootState) => state.settings)

    const [scene, setScene] = useState<Scene>()

    const setIsLoading = (isLoading: boolean) => {
        dispatch(handleLoading(isLoading))
    };

    useEffect(() => {
        if (didInit) {
            console.warn("Warning - tried to initialize twice. Will skip 2nd initialization.");
            return;
        }

        if (tenant) {
            dispatch(getModelByTenantName(tenant))
                .then((res) => {
                    if (res.type === composeThunkResponse([MODEL, GETTENANT, FULFILLED])) {
                        try {
                            if (canvasRef.current) {
                                const modelName = res.payload.model
                                const isSkybox = res.payload.backgroundOptions === skyboxOption
                                const skyboxInput = isSkybox ? res.payload.background : ''
                                const scene = initBabylon(setIsLoading, modelName, canvasRef.current, isSkybox, skyboxInput);
                                setScene(scene)
                                setHumanoid(humanoidRef, modelName, scene);
                            }

                        } catch (error) {
                            console.error(error);
                            if (error instanceof Error) {
                                dispatch(handleInitErrorMessage(error.message))
                            }
                        }
                    } else if (res.type === composeThunkResponse([MODEL, GETTENANT, REJECTED])) {
                        navigate("/Notfound")
                    }
                }).catch((err: any) => {
                    console.log("Unexpected:", err)
                })
        }
    }, [didInit, tenant, dispatch])

    return (
        <>
            {modelInfo && modelInfo.model && scene && didInit && !isLoading &&
                <button className="btn bg-primary-subtle hstack gap-2 reset-view-btn shadow-sm" onClick={() => updateCamera(modelInfo.model, scene)}>
                    <span className="d-none d-md-block">Reset View</span>
                    <span className="d-md-none hstack gap-1">
                        <FontAwesomeIcon icon={faStreetView} />
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </span>
                </button>}
            <canvas ref={canvasRef} className="canvas-element" />
        </>
    );
});

export default CanvasThatDoesNotReRender