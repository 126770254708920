import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { composeThunkResponse } from 'src/common/commonUtils'
import { FULFILLED, MODEL, PENDING, REJECTED, VALIDATEPASSWORD } from 'src/constants/constants'
import { passwordData, validateModelPassword } from 'src/store/slices/modelSlice'
import { RootState, useAppDispatch } from 'src/store/store'
import * as Yup from 'yup'

interface ValidateModelPasswordProps {
    tenant: string
    handleIsModelValidated: (isModelValidated: boolean) => void
}
const ValidateModelPassword: FC<ValidateModelPasswordProps> = ({ tenant, handleIsModelValidated }) => {
    const dispatch = useAppDispatch()

    const { modelStatus } = useSelector((state: RootState) => state.model)

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const passwordInitialValues = { password: '' }
    const passwordValidationSchema = Yup.object().shape({
        password: Yup.string().required("This field is required"),
    })

    const handleSubmitPassword = (formValue: any) => {
        const { password } = formValue
        const passwordData: passwordData = {
            tenant: tenant,
            password: password
        }
        dispatch(validateModelPassword(passwordData)).then((res: any) => {
            if (res.type === composeThunkResponse([MODEL, VALIDATEPASSWORD, FULFILLED])) {
                handleIsModelValidated(true)
            } else if (res.type === composeThunkResponse([MODEL, VALIDATEPASSWORD, REJECTED])) {
                handleIsModelValidated(false)
            }
        })
    }

    return (
        <div className='container mt-5'>
            <Formik
                initialValues={passwordInitialValues}
                validationSchema={passwordValidationSchema}
                onSubmit={handleSubmitPassword}
            >
                <Form className='vstack gap-3'>
                    <label htmlFor="password">Enter password for <strong>Tenant: {tenant.toUpperCase()}</strong> to access further</label>
                    <div className='position-relative'>
                        <Field type={showPassword ? "text" : "password"} name="password" className="form-control pe-5" />
                        <button className='btn d-flex align-items-center p-0 position-absolute translate-middle-y top-50 end-0 me-2' type='button' onClick={() => setShowPassword(!showPassword)}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <ErrorMessage name="password" component="div" className="error" />
                    <button className='btn btn-primary hstack gap-2 justify-content-center' type='submit' disabled={modelStatus === PENDING}>
                        <span>Submit</span> {modelStatus === PENDING && <Spinner size='sm' />}
                    </button>
                </Form>
            </Formik>
        </div>
    )
}

export default ValidateModelPassword