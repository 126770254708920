import { faChevronLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { composePath, composeThunkResponse, isValidColor, mbTobytes } from 'src/common/commonUtils'
import { MAX_PROMPT_LENGTH, models } from 'src/config/modelConfig'
import { FULFILLED, GETTENANT, MODEL, PENDING, REJECTED, SAVETENANT, SETTINGS } from 'src/constants/constants'
import { voices } from 'src/constants/voices'
import { colorOption, getModelByTenantName, imageOption, initialModelInfo, ModelInfoState, saveTenant, skyboxOption } from 'src/store/slices/modelSlice'
import { RootState, useAppDispatch } from 'src/store/store'
import * as Yup from 'yup'


// change this to '' if needed
const defaultBgImage = 'https://images.unsplash.com/photo-1642336186832-522a31a4d12f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
const defaultBgColor = "#ffffff"
const defaultSkybox = 'https://d33yhpagh5wzlm.cloudfront.net/scene1.env'
const MAX_FILE_SIZE = 10 //in MB
const ALLOWED_TYPES = ["application/pdf"]

export interface ModelSettings extends ModelInfoState {
    imageOptionInput: string
    colorTextInput: string
    colorOptionInput: string
    skyboxOptionInput: string
    document: null | File
}

const ModelSettings = () => {
    const dispatch = useAppDispatch()
    let navigate = useNavigate()
    let { tenant } = useParams()
    let createMode = tenant === 'new'

    const { modelStatus } = useSelector((state: RootState) => state.model)

    const [formLoader, setFormLoader] = useState<boolean>(true)
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const defaultValues: ModelSettings = {
        ...initialModelInfo,
        document: null,
        imageOptionInput: defaultBgImage,
        colorTextInput: defaultBgColor,
        colorOptionInput: defaultBgColor,
        skyboxOptionInput: defaultSkybox
    }

    const [initialValues, setInitialValues] = useState<ModelSettings>(defaultValues)

    const validationSchema = Yup.object().shape({
        model: Yup.string().required("This field is required").oneOf(Object.keys(models), "Invalid selection"),
        voice: Yup.string().required("This field is required").oneOf(Object.keys(voices), "Invalid selection"),
        role: Yup.string().required("This field is required"),
        prompt: Yup.string().required("This field is required")
            .test(
                "max-characters",
                `Max ${MAX_PROMPT_LENGTH} characters are allowed`,
                (value: string) => value.length <= MAX_PROMPT_LENGTH
            ),
        initialMessage: Yup.string().required("This field is required"),
        document: Yup.mixed()
            .nullable()
            .notRequired()
            .test("fileType", `Please upload a pdf file.`,
                (value: any) => !value || (value && ALLOWED_TYPES.includes(value.type))
            )
            .test("fileSize", `Max allowed file size is ${MAX_FILE_SIZE}MB`,
                (value: any) => !value || (value && value.size <= mbTobytes(MAX_FILE_SIZE))),
        tenantName: Yup.string().required("This field is required"),
        password: Yup.string().required("This field is required"),

        backgroundOptions: Yup.string().required("This field is required"),
        imageOptionInput: Yup.string().when('backgroundOptions', {
            is: (value: any) => value === imageOption,
            then: (schema: any) => schema.required("This field is required")
        }),
        colorTextInput: Yup.string().when('backgroundOptions', {
            is: (value: string) => value === colorOption,
            then: (schema: any) => schema.required("This field is required")
                .test(
                    "Check Color",
                    "Please enter a valid color code",
                    (value: string) => isValidColor(value)
                )
        }),
        colorOptionInput: Yup.string().when('backgroundOptions', {
            is: (value: string) => value === colorOption,
            then: (schema: any) => schema.required("This field is required")
        }),
        skyboxOptionInput: Yup.string().when('backgroundOptions', {
            is: (value: string) => value === skyboxOption,
            then: (schema: any) => schema.required("This field is required")
                .test(
                    "Check Extension",
                    "Please enter a valid url (.env extension)",
                    (value: string) => value.slice(-4) === ".env"
                )
        }),
    })

    const handleSubmit = (formValue: ModelSettings) => {
        console.log("formValue", formValue);
        const {
            model,
            voice,
            role,
            prompt,
            documentName,
            assistant_id,
            initialMessage,
            speechRecognitionLanguageCode,
            tenantName,
            password,
            backgroundOptions,
            imageOptionInput,
            colorOptionInput,
            skyboxOptionInput,
            document,
            vector_id,
        } = formValue

        let background: string = '';
        if (backgroundOptions === imageOption) {
            background = imageOptionInput
        }
        if (backgroundOptions === colorOption) {
            background = colorOptionInput
        }
        if (backgroundOptions === skyboxOption) {
            background = skyboxOptionInput
        }

        const formData = new FormData();

        formData.append('model', model)
        formData.append('voice', voice)
        formData.append('role', role.trim())
        formData.append('prompt', prompt.trim())
        formData.append('documentName', !!document ? document.name.replaceAll(" ", "_") : documentName)
        formData.append('assistant_id', assistant_id)
        formData.append('initialMessage', initialMessage.trim())
        formData.append('speechRecognitionLanguageCode', speechRecognitionLanguageCode)
        formData.append('background', background.trim())
        formData.append('tenantName', tenantName.trim().toLowerCase())
        formData.append('password', password.trim())
        formData.append('backgroundOptions', backgroundOptions)
        formData.append('vector_id', vector_id)
        !!document && formData.append('document', document)

        dispatch(saveTenant(formData)).then((res: any) => {
            if (res.type === composeThunkResponse([MODEL, SAVETENANT, FULFILLED])) {
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if (tenant && !createMode) {
            dispatch(getModelByTenantName(tenant)).then((res: any) => {
                if (res.type === composeThunkResponse([MODEL, GETTENANT, FULFILLED])) {
                    const bgOptions = res.payload.backgroundOptions
                    const bgValue = res.payload.background
                    console.log("res", res.payload);


                    const setOptionValue = (option: string, defaultValue: string) => {
                        return bgOptions === option ? bgValue : defaultValue
                    }

                    setInitialValues({
                        ...res.payload,
                        imageOptionInput: setOptionValue(imageOption, defaultBgImage),
                        colorTextInput: setOptionValue(colorOption, defaultBgColor),
                        colorOptionInput: setOptionValue(imageOption, defaultBgColor),
                        skyboxOptionInput: setOptionValue(skyboxOption, defaultSkybox),
                    })
                    setFormLoader(false)
                }
                if (res.type === composeThunkResponse([MODEL, GETTENANT, REJECTED])) {
                    navigate(composePath(SETTINGS))
                    setFormLoader(false)
                }
            })
        } else {
            setFormLoader(false)
        }
    }, [tenant, createMode, dispatch])


    const syncColorInputs = (setFieldValue: (field: string, value: string) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value
            setFieldValue("colorTextInput", value)
            setFieldValue("colorOptionInput", value)
        }


    const getFile = async (filename: string) => {
        return axios.get(process.env.REACT_APP_API_URL + "uploads/" + filename)
            .then((response) => {
                return response;
            });
    };


    return (
        <div className='container my-5'>
            <div className='hstack justify-content-between gap-2 flex-wrap'>
                <div className='hstack gap-2'>
                    <Link to={composePath(SETTINGS)} className='btn btn-primary'>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                    <h2 className='mb-1'> {createMode ? "Create New" : 'Model Settings'}</h2>
                </div>
                <h3 className='text-uppercase mb-0'>{initialValues.tenantName}</h3>
            </div>
            <hr />
            {formLoader ?
                <div className='text-center mt-5 pt-5'>
                    <Spinner />
                </div>
                :
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, values }) => (
                        <Form className='vstack gap-4'>
                            <div>
                                <label htmlFor="model">Select a Model*</label>
                                <Field as="select" name="model" className="form-control text-capitalize">
                                    {Object.keys(models).map((model: string) =>
                                        <option key={model} value={model}>{model.replaceAll("_", " ")}</option>
                                    )}
                                </Field>
                                <ErrorMessage name="model" component="div" className="error" />
                            </div>

                            <div>
                                <label htmlFor="voice">Select a Voice*</label>
                                <Field as="select" name="voice" className="form-control">
                                    {Object.keys(voices).map((voice: string) =>
                                        <option key={voice} value={voice}>{voice}</option>
                                    )}
                                </Field>
                                <ErrorMessage name="voice" component="div" className="error" />
                            </div>

                            <div>
                                <label htmlFor="role">
                                    Role*
                                </label>
                                <Field
                                    id="role"
                                    className="form-control"
                                    type="text"
                                    name="role"
                                />
                                <ErrorMessage name="role" component="div" className="error" />
                            </div>

                            <div>
                                <label htmlFor="prompt">Prompt*</label>
                                <div className='position-relative'>
                                    <Field component="textarea" rows={5} name="prompt" className="form-control"></Field>
                                    <small className='position-absolute bottom-0 end-0 me-3 text-muted'>
                                        <span className={values.prompt.length > MAX_PROMPT_LENGTH ? 'text-danger' : ''}>{values.prompt.length}</span>
                                        <span>/{MAX_PROMPT_LENGTH}</span>
                                    </small>
                                </div>
                                <ErrorMessage name="prompt" component="div" className="error" />
                            </div>

                            <div>
                                <label htmlFor="initialMessage">
                                    Initial Message*
                                </label>
                                <Field
                                    id="initialMessage"
                                    className="form-control"
                                    type="text"
                                    name="initialMessage"
                                />
                                <ErrorMessage name="initialMessage" component="div" className="error" />
                            </div>

                            <div>
                                {initialValues.documentName !== '' &&
                                    <div className='hstack gap-2'>
                                        <span>Current Document:</span>
                                        <span>{initialValues.documentName}</span>
                                    </div>
                                }
                                <label htmlFor="document">Upload a pdf <small className='text-muted'>(optional) (max {MAX_FILE_SIZE}MB)</small> </label>
                                <div className='hstack gap-3'>
                                    <input
                                        className="form-control"
                                        id="document"
                                        name="document"
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("document", e.currentTarget.files ? e.currentTarget.files[0] : null);
                                        }}
                                    />
                                </div>
                                <ErrorMessage name="document" component="div" className="error" />
                            </div>

                            <div className='vstack gap-2'>
                                <label htmlFor="backgroundOptions">Background*</label>
                                <div className='hstack gap-3'>
                                    <label className='hstack gap-2'>
                                        <Field type="radio" name="backgroundOptions" value={imageOption} />
                                        <span>Image url</span>
                                    </label>
                                    <label className='hstack gap-2'>
                                        <Field type="radio" name="backgroundOptions" value={colorOption} />
                                        <span>Color</span>
                                    </label>
                                    <label className='hstack gap-2'>
                                        <Field type="radio" name="backgroundOptions" value={skyboxOption} />
                                        <span>Skybox url</span>
                                    </label>
                                    <ErrorMessage name="backgroundOptions" component="div" />
                                </div>

                                {values.backgroundOptions === imageOption &&
                                    <>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="imageOptionInput"
                                            placeholder="enter image url"
                                        />
                                        <ErrorMessage name="imageOptionInput" component="div" className="error" />
                                    </>
                                }

                                {values.backgroundOptions === colorOption &&
                                    <div className='hstack gap-3'>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="colorTextInput"
                                            onChange={syncColorInputs(setFieldValue)}
                                        />
                                        <Field
                                            className="size-48"
                                            type="color"
                                            name="colorOptionInput"
                                            onChange={syncColorInputs(setFieldValue)}
                                        />
                                        <ErrorMessage name="colorTextInput" component="div" className="error" />
                                    </div>
                                }

                                {values.backgroundOptions === skyboxOption &&
                                    <>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="skyboxOptionInput"
                                            placeholder="enter skybox file url (.env extension)"
                                        />
                                        <ErrorMessage name="skyboxOptionInput" component="div" className="error" />
                                    </>
                                }
                            </div>

                            <div>
                                <label htmlFor="tenantName">
                                    Tenant name*
                                </label>
                                <Field
                                    id="tenantName"
                                    className="form-control"
                                    type="text"
                                    name="tenantName"
                                    disabled={!createMode}
                                />
                                <ErrorMessage name="tenantName" component="div" className="error" />
                            </div>
                            <div>
                                <label htmlFor="password">Password*</label>
                                <div className='position-relative'>
                                    <Field type={showPassword ? "text" : "password"} name="password" className="form-control pe-5" />
                                    <button className='btn d-flex align-items-center p-0 position-absolute translate-middle-y top-50 end-0 me-2' type='button' onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <ErrorMessage name="password" component="div" className="error" />
                            </div>
                            <button className='btn btn-primary mt-5 hstack gap-2 justify-content-center' type='submit' disabled={modelStatus === PENDING}>
                                <span>Submit</span> {modelStatus === PENDING && <Spinner size='sm' />}
                            </button>
                        </Form>
                    )}
                </Formik>
            }
        </div>
    )
}

export default ModelSettings
