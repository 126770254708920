import axios from 'axios';
import { GETALLTENANTS, MODEL, SAVETENANT, VALIDATEPASSWORD } from 'src/constants/constants';
import { composeBaseURL, handleErrors, composePath } from 'src/common/commonUtils';
import { passwordData } from 'src/store/slices/modelSlice';

// Define the base URL for your API
const BASEAPI_URL = MODEL;

// Create an Axios instance
const api = axios.create({
    baseURL: composeBaseURL(BASEAPI_URL),
    headers: { 'Content-Type': 'application/json' },
});

// API functions
export const apiGetModelByTenantName = async (tenantName: string): Promise<any> => {
    try {
        const response = await api.get(tenantName);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

// export const apiSaveJson = async (modelData: any): Promise<any> => {
//     try {

//         const response = await api.post(composePath(SAVETENANT), modelData);
//         return response.data;
//     } catch (error) {
//         throw new Error(handleErrors(error));
//     }
// };

export const apiSaveJson = async (modelData: any): Promise<any> => {
    try {

        const response = await axios.post(composeBaseURL(BASEAPI_URL) + composePath(SAVETENANT), modelData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiGetAllTenants = async (): Promise<any> => {
    try {

        const response = await api.get(composePath(GETALLTENANTS));
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiDeleteTenant = async (tenantName: string): Promise<any> => {
    try {
        const response = await api.delete(tenantName);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiGetFile = async (): Promise<any> => {
    try {
        const response = await api.get(composePath(""));
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiValidateModelPassword = async (passwordData: passwordData): Promise<any> => {
    try {

        const response = await api.post(composePath(VALIDATEPASSWORD), passwordData);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};