import axios from 'axios';
import { CHAT, SENDMESSAGE } from 'src/constants/constants';
import { composeBaseURL, handleErrors, composePath } from 'src/common/commonUtils';
import { SendMessageData } from 'src/store/slices/chatSlice';

// Define the base URL for your API
const BASEAPI_URL = CHAT;

// Create an Axios instance
const api = axios.create({
    baseURL: composeBaseURL(BASEAPI_URL),
    headers: { 'Content-Type': 'application/json' },
});


// API functions
export const apiSendMessage = async (payload: SendMessageData) => {
    try {
        const response = await api.post(composePath(SENDMESSAGE), payload);
        return response;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

//with abort signal 
// export const apiSendMessage = async (payload: SendMessageData) => {
//     try {
//         const response = await axios.post(composeBaseURL(BASEAPI_URL) + composePath(SENDMESSAGE), payload,
//             {
//                 headers: { 'Content-Type': 'application/json' },
//                 signal: payload.signal
//             }
//         );
//         return response.data;
//     } catch (error) {
//         throw new Error(handleErrors(error));
//     }
// };