import { v3 } from "src/common/plugins/Babylon/utils";
import { CameraConfig, Model, ModelConfig } from "src/types/types";

export const MAX_WORD_SUGGESTION = 60;

export const OPENAI_TIMEOUT_MILLISECONDS = 600000;

export const MAX_CHARS = 300;

export const MAX_PROMPT_LENGTH = 512

export const MAIN_CAMERA_NAME = "MainCamera";

export const DEFAULT_INITIAL_MESSAGE = "Hey, how's it going?";

export const DEFAULT_ROLE = 'Financial Analyst Assistant';

export const DEFAULT_PROMPT = `You are Forencia, a structuralist economist. Your key concepts include:
1. Center-Periphery Model: Explain global economic structure and its effect on developing nations.
2. Terms of Trade: Analyze the decline for primary commodity exporters.
3. Industrialization: Advocate for industrial growth in developing countries.
4. Economic Policy: Suggest sustainable policies for growth.
5. Neoclassical Economics: Critique its limitations for developing economies.`;
export const DEFAULT_SPEECH_RECOGNITION_LANGUAGE_CODE = "en-US";

export const DEFAULT_VOICE = "en-GB-Standard-A";

export const DEFAULT_MODEL: Model = "forencia";

// assisstant id which is generated from python when upload a pdf
// export const DEFAULT_ASSISTANT_ID = "asst_lizeiZyFxfoXfdizzBH39hkE";

export const defaultCameraConfig: CameraConfig = {
    alpha: Math.PI / 2,
    beta: Math.PI / 2.5,
    radius: 2.5,
    target: v3(0, 0.7, 0),
};

const defaultConfig: ModelConfig = {
    cameraConfig: defaultCameraConfig,
    voice: "en-US-Neural2-H",
    initialAnimation: "idle3_hand_hips",
    faceMeshName: "Face",
    morphTargets: {
        mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
        leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close_L",
        rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close_R",
    },
    idleAnimations: ["idle1", "idle2", "idle3_hand_hips"],
    talkingBodyAnimations: ["talking1", "talking2_head_shake", "talking3"],
    positionOffset: v3(0, 0, 0),
};

export const models = {
    vroid_girl2: defaultConfig,
    adam_smith: {
        cameraConfig: { ...defaultCameraConfig, radius: 3.3, target: v3(0, 1, 0) },
        voice: "en-GB-Standard-D",
        initialAnimation: "Idle01",
        faceMeshName: "Face_primitive0",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["Idle01", "Idle02", "Idle03"],
        talkingBodyAnimations: ["Talking01", "Talking02", "Talking03"],
        positionOffset: v3(0, 0, 0),
    },
    milton_friedman: {
        cameraConfig: { ...defaultCameraConfig, radius: 3, target: v3(0, 1, 0), },
        voice: "en-GB-Standard-D",
        initialAnimation: "Idle001",
        faceMeshName: "Face_primitive2",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["Idle001", "Idle002", "Idle003"],
        talkingBodyAnimations: ["Talking001", "Talking002", "Talking003"],
        positionOffset: v3(0, 0, 0),
    },
    joe_shaker: {
        cameraConfig: { ...defaultCameraConfig, radius: 3, target: v3(0, 1, 0), },
        voice: "en-GB-Standard-D",
        initialAnimation: "Idle001",
        faceMeshName: "Face_primitive1",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["Idle001", "Idle002", "Idle003"],
        talkingBodyAnimations: ["Talking001", "Talking002", "Talking003"],
        positionOffset: v3(0, 0, 0),
    },
    samuel_adams: {
        cameraConfig: { ...defaultCameraConfig, radius: 3.3, target: v3(0, 1, 0) },
        voice: "en-GB-Standard-D",
        initialAnimation: "Idle01",
        faceMeshName: "Face_primitive0",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["Idle01", "Idle02", "Idle03"],
        talkingBodyAnimations: ["Talking01", "Talking02", "Talking03"],
        positionOffset: v3(0, 0, 0),
    },
    forencia: {
        cameraConfig: { ...defaultCameraConfig, radius: 2.7, target: v3(0, 1, 0) },
        voice: "en-GB-Standard-A",
        initialAnimation: "idle3_hand_hips",
        faceMeshName: "Face_primitive0",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["idle1", "idle2", "idle3_hand_hips"],
        talkingBodyAnimations: ["talking1", "talking3"],
        positionOffset: v3(0, 0.015, 0),
    },
    vest_dude: {
        ...defaultConfig,
        morphTargets: {
            mouthMovement: "mouth_open",
        },
        faceMeshName: "rp_eric_rigged_001_geo",
        cameraConfig: {
            alpha: Math.PI / 2,
            beta: Math.PI / 2.5,
            radius: 3,
            target: v3(0, 0.9, 0),
        },
        positionOffset: v3(0, 0.03, 0),
    },
    sucess: {
        ...defaultConfig,
        morphTargets: {
            mouthMovement: "Mouth",
            leftEyeClose: "left_eye",
            rightEyeClose: "right_eye",
        },
        faceMeshName: "Object.4",
        cameraConfig: {
            alpha: Math.PI / 2,
            beta: Math.PI / 2.5,
            radius: 3,
            target: v3(0, 0.9, 0),
        },
        positionOffset: v3(0, 0.03, 0),
    },
};
