import { models } from "src/config/modelConfig";
import { API_URL } from "../constants/constants";
import { speechRecognitionLanguages } from "src/constants/speechRecognitionLanguages";


// Helper functions
export const composeBaseURL = (baseURL: string) => {
    //will return ex: http://localhost:xxxx/api/baseURL
    return API_URL + baseURL
}

export const composeThunkAction = (path: string, subPath: string) => {
    //will add '/' in between and return ex: auth/signin
    return path + "/" + subPath
}

export const composeThunkResponse = (keywords: string[]) => {
    //will add '/' in between but not in last and return ex: auth/signin/rejected
    let responseType = ''
    keywords.map((key: string) => {
        responseType = responseType.concat(key, '/')
    })
    return responseType.slice(0, -1)
}

export const composePath = (path: string) => {
    //will add '/' and return ex: /signin 
    return "/" + path
}

export const handleErrors = (error: any) => {
    if (error.response && error.response.data) {
        return error.response.data.error || 'An error occurred';
    }
    return 'Network error';
};

export function isWebview(): boolean {
    if (typeof window === undefined) {
        return false;
    }

    let navigator = window.navigator;

    const standalone = (navigator as any).standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
    const ios_ipad_webview = ios && !safari;

    return ios ? (!standalone && !safari) || ios_ipad_webview : userAgent.includes("wv");
}

export function isBabylonInspectorShowing() {
    return (
        process.env.NODE_ENV === "development" &&
        typeof window !== "undefined" &&
        document.getElementById("sceneExplorer")
    );
}

export const isModelValid = (model: string | null): boolean => {
    if (!model) {
        return false;
    }

    if (!Object.keys(models).includes(model)) {
        return false;
    }

    return true;
};

export const isSpeechLangCodeValid = (languageCode: string | null): boolean => {
    if (!languageCode) {
        return false;
    }

    if (!Object.keys(speechRecognitionLanguages).includes(languageCode)) {
        return false;
    }

    return true;
}

export const isValidColor = (color: string) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== '';
}

export const mbTobytes = (mb: number) => {
    return 1024 * 1024 * mb
}