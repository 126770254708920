import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { composeThunkAction } from 'src/common/commonUtils';
import { REJECTED, IDLE, GETTENANT, PENDING, MODEL, FULFILLED, SAVETENANT, GETALLTENANTS, DELETETENANT, VALIDATEPASSWORD } from 'src/constants/constants';
import { DEFAULT_INITIAL_MESSAGE, DEFAULT_MODEL, DEFAULT_PROMPT, DEFAULT_ROLE, DEFAULT_SPEECH_RECOGNITION_LANGUAGE_CODE, DEFAULT_VOICE } from 'src/config/modelConfig';
import { apiDeleteTenant, apiGetAllTenants, apiGetModelByTenantName, apiSaveJson, apiValidateModelPassword } from 'src/services/model.service';
import { Model, SpeechRecognitionLanguageCode, StatusError, StatusType, Voice } from 'src/types/types';
import { toast } from 'react-toastify';

// Define types
export const imageOption = "imageOption"
export const colorOption = "colorOption"
export const skyboxOption = "skyboxOption"
export type BgOptions = typeof imageOption | typeof colorOption | typeof skyboxOption

export type passwordData = {
    tenant: string
    password: string
}
export interface ModelInfoState {
    model: Model
    voice: Voice
    role: string
    prompt: string
    documentName: string
    assistant_id: string
    initialMessage: string
    speechRecognitionLanguageCode: SpeechRecognitionLanguageCode
    background: string
    tenantName: string
    backgroundOptions: BgOptions
    vector_id: string
    password: string
    thread_id: string
}

export const initialModelInfo: ModelInfoState = {
    model: DEFAULT_MODEL,
    voice: DEFAULT_VOICE,
    role: DEFAULT_ROLE,
    prompt: DEFAULT_PROMPT,
    documentName: '',
    assistant_id: '',
    initialMessage: DEFAULT_INITIAL_MESSAGE,
    speechRecognitionLanguageCode: DEFAULT_SPEECH_RECOGNITION_LANGUAGE_CODE,
    background: '',
    tenantName: '',
    backgroundOptions: imageOption,
    vector_id: '',
    password: '',
    thread_id: ''
}

interface ModelState {
    modelInfo: ModelInfoState;
    modelStatus: StatusType;
    modelError: StatusError;
    didInit: boolean
    tenants: string[]
}


// Define the initial state
const initialState: ModelState = {
    modelInfo: initialModelInfo,
    modelStatus: IDLE,
    modelError: null,
    didInit: false,
    tenants: []
}


// Create async thunks
export const getModelByTenantName = createAsyncThunk(
    composeThunkAction(MODEL, GETTENANT),
    async (tenantName: string, { rejectWithValue }) => {
        try {
            const data = await apiGetModelByTenantName(tenantName);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveTenant = createAsyncThunk(
    composeThunkAction(MODEL, SAVETENANT),
    async (modelData: FormData, { rejectWithValue }) => {
        try {
            const data = await apiSaveJson(modelData);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const getAllTenants = createAsyncThunk(
    composeThunkAction(MODEL, GETALLTENANTS),
    async (_, { rejectWithValue }) => {
        try {
            const data = await apiGetAllTenants();
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteTenant = createAsyncThunk(
    composeThunkAction(MODEL, DELETETENANT),
    async (tenantName: string, { rejectWithValue }) => {
        try {
            const data = await apiDeleteTenant(tenantName);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const validateModelPassword = createAsyncThunk(
    composeThunkAction(MODEL, VALIDATEPASSWORD),
    async (passwordData: passwordData, { rejectWithValue }) => {
        try {
            const data = await apiValidateModelPassword(passwordData);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


// Create the slice
const modelSlice = createSlice({
    name: MODEL,
    initialState,
    reducers: {
        setModelInfo: (state, { payload }: PayloadAction<ModelInfoState>) => {
            state.modelInfo = payload
        }
        // Optional synchronous actions can be defined here
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModelByTenantName.pending, (state) => {
                state.modelStatus = PENDING;
                state.modelError = null; // Clear the error when the request is pending
            })
            .addCase(getModelByTenantName.fulfilled, (state, action: PayloadAction<any>) => {
                state.modelStatus = FULFILLED;
                state.modelInfo = action.payload;
                state.didInit = true
            })
            .addCase(getModelByTenantName.rejected, (state, action) => {
                state.modelStatus = REJECTED;
                state.modelError = action.payload as string; // Use the custom error message
                state.didInit = false
                toast.error(action.payload as string)
            })
            .addCase(saveTenant.pending, (state) => {
                state.modelStatus = PENDING;
                state.modelError = null; // Clear the error when the request is pending
            })
            .addCase(saveTenant.fulfilled, (state, action: PayloadAction<any>) => {
                state.modelStatus = FULFILLED;
                state.modelInfo = action.payload;
                toast.success(action.payload.message)
            })
            .addCase(saveTenant.rejected, (state, action) => {
                state.modelStatus = REJECTED;
                state.modelError = action.payload as string; // Use the custom error message
                toast.error(action.payload as string)
            })
            .addCase(getAllTenants.pending, (state) => {
                state.modelStatus = PENDING;
                state.modelError = null; // Clear the error when the request is pending
            })
            .addCase(getAllTenants.fulfilled, (state, action: PayloadAction<any>) => {
                state.modelStatus = FULFILLED;
                state.tenants = action.payload
            })
            .addCase(getAllTenants.rejected, (state, action) => {
                state.modelStatus = REJECTED;
                state.modelError = action.payload as string; // Use the custom error message
                toast.error(action.payload as string)
            })
            .addCase(deleteTenant.pending, (state) => {
                state.modelStatus = PENDING;
                state.modelError = null; // Clear the error when the request is pending
            })
            .addCase(deleteTenant.fulfilled, (state, action: PayloadAction<any>) => {
                state.modelStatus = FULFILLED;
                state.tenants = action.payload.tenants
                toast.success(action.payload.message)
            })
            .addCase(deleteTenant.rejected, (state, action) => {
                state.modelStatus = REJECTED;
                state.modelError = action.payload as string; // Use the custom error message
                toast.error(action.payload as string)
            })
            .addCase(validateModelPassword.pending, (state) => {
                state.modelStatus = PENDING;
                state.modelError = null; // Clear the error when the request is pending
            })
            .addCase(validateModelPassword.fulfilled, (state, action: PayloadAction<any>) => {
                state.modelStatus = FULFILLED;
                toast.success(action.payload.message)
            })
            .addCase(validateModelPassword.rejected, (state, action) => {
                state.modelStatus = REJECTED;
                state.modelError = action.payload as string;
                toast.error(action.payload as string)
            })
    },
});

export const { setModelInfo } = modelSlice.actions
export default modelSlice.reducer;
