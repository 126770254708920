import ChatMessages from './ChatMessages'
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ChatTextArea } from './ChatTextArea';
import { ChatErrors } from './ChatErrors';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Chat = () => {
    const { chatError, charCount } = useSelector((state: RootState) => state.chat);

    const [expandView, setExpandView] = useState<boolean>(false)

    return (
        <div className={`chat-wrapper ${expandView ? 'expandView' : ''}`}>
            <button className='btn btn-success d-md-none expand-view-btn' onClick={() => setExpandView(!expandView)}>
                <FontAwesomeIcon icon={faChevronUp} />
            </button>

            <ChatMessages />
            <ChatTextArea />

            <ChatErrors errorMessage={chatError} charCount={charCount} />
        </div>
    )
}

export default Chat